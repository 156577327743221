// ** Default InfoTIS script data
const defaultInfotisScript = {
  id: null,
  name: "",
  appId: "",
  modules: [],
  isActive: true
}

// ** Initial State
const initialState = {
  pending: false,
  allScripts: [],
  selectedInfotisScript: {...defaultInfotisScript}
}

const infotisScripts = (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_INFOTIS_SCRIPT':
      return {
        ...state,
        pending: true
      }
    case 'FINISH_LOADING_INFOTIS_SCRIPT':
      return {
        ...state,
        pending: false
      }
    case 'RESET_SELECTED_INFOTIS_SCRIPT':
      return {
        ...state,
        selectedInfotisScript: {...defaultInfotisScript}
      }
    case 'GET_ALL_SCRIPTS':
      return {
        ...state,
        allScripts: action.infotisScripts,
        pending: false
       }
    case 'GET_INFOTIS_SCRIPT':
      return {
        ...state,
        selectedInfotisScript: action.selectedInfotisScript,
        pending: false
       }
    case 'ADD_INFOTIS_SCRIPT':
      return {
        ...state
       }
    case 'EDIT_INFOTIS_SCRIPT':
      return {
        ...state,
        selectedInfotisScript: action.selectedInfotisScript
       }
    case 'ADD_MODULE_TO_INFOTIS_SCRIPT':
      return {
        ...state,
        pending: false
      }
    case 'EDIT_MODULE_PARAMS':
      return {
        ...state,
        pending: false
      }
    case 'MODIFY_MODULES_ORDER_INFOTIS_SCRIPT':
      return {
        ...state,
        pending: false
      }
    case 'REMOVE_MODULE_FROM_INFOTIS_SCRIPT':
      return {
        ...state,
        selectedInfotisScript: {
          ...state.selectedInfotisScript,
          modules: state.selectedInfotisScript.modules.filter((entry) => entry.optionId !== action.deletedOptionId)
        },
        pending: false
      }
    case 'DELETE_INFOTIS_SCRIPT':
      return {
        ...state,
        allScripts: state.allScripts.filter((entry) => entry.id !== action.deletedId),
        pending: false
       }
    default:
      return { ...state }
  }
}
export default infotisScripts
