// ** Initial State
const defaultTrack = {
  id: null,
  name: "",
  appId: "",
  json: "",
  isCompleted : false,
  isActive: true
}

const trackInitialState = {
  loading: false,
  allData: [],
  selectedTrack: {...defaultTrack}
}

const tracks = (state = trackInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_TRACK':
      return {
        ...state,
        loading: true
       }
    case 'FINISH_LOADING_TRACK':
      return {
        ...state,
        loading: false
       }
    case 'RESET_SELECTED_TRACK':
      return {
        ...state,
        selectedTrack: {...defaultTrack}
      }
    case 'GET_TRACKS':
      return {
        ...state,
        allData: action.allData,
        loading: false
       }
    case 'GET_TRACK':
      return {
        ...state,
        selectedTrack: action.selectedTrack,
        loading: false
       }
    case 'ADD_TRACK':
      return {
        ...state
       }
    case 'EDIT_TRACK':
      return {
        ...state,
        selectedTrack: action.selectedTrack
       }
    case 'DELETE_TRACK':
      return {
        ...state,
        allData: state.allData.filter((entry) => entry.id !== action.deletedId),
        loading: false
       }
    case 'BEGIN_LOADING_AUTO_TREE':
      return {
        ...state,
        loading: true
       }
    case 'FINISH_LOADING_AUTO_TREE':
      return {
        ...state,
        loading: false
      }
    case 'AUTO_TREE':
        return {
          ...state
         }
    default:
      return { ...state }
  }
}
export default tracks
