const individualReportInitialState = {
  loading: false,
  qsEmbedUrl: null,
  lastEmbedUrlReceivedAt: null,
  companyPlayers: null
}

const individualReport = (state = individualReportInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_PLAYERS_REPORT':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_PLAYERS_REPORT':
      return {
        ...state,
        loading: false
      }
    case 'GET_PLAYERS_REPORT':
      return {
        ...state,
        qsEmbedUrl: action.qsEmbedUrl,
        companyPlayers: action.companyPlayers,
        lastEmbedUrlReceivedAt: new Date(),
        loading: false
       }
    default:
      return { ...state }
  }
}
export default individualReport
