const userLogsInitialState = {
  loading: false,
  userLogs: [],
  totalCount: 0
}

const userLogs = (state = userLogsInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_USER_LOGS':
      return {
        ...state,
        loading: true
       }
    case 'FINISH_LOADING_USER_LOGS':
      return {
        ...state,
        loading: false
       }
    case 'GET_USER_LOGS':
      return {
        ...state,
        userLogs: action.userLogs,
        totalCount: action.totalCount,
        loading: false
       }
    default:
      return { ...state }
  }
}
export default userLogs
