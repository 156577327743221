// ** React Imports
import {useState, createContext, useEffect} from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesPt from '@assets/data/locales/pt.json'
import messagesEn from '@assets/data/locales/en.json'
// ** import messagesDe from '@assets/data/locales/de.json'
// ** import messagesFr from '@assets/data/locales/fr.json'

// ** User Language Data
import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesEn from '@src/assets/data/locales/en.json'
// ** import userMessagesDe from '@src/assets/data/locales/de.json'
// ** import userMessagesFr from '@src/assets/data/locales/fr.json'

// ** Menu msg obj
const menuMessages = {
  pt: { ...messagesPt, ...userMessagesPt },
  en: { ...messagesEn, ...userMessagesEn }
  // de: { ...messagesDe, ...userMessagesDe },
  // fr: { ...messagesFr, ...userMessagesFr },
}

// ** Create Context
const Context = createContext()

const LANGUAGE_PREFERENCE_KEY = 'languagePreference'

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('pt')
  const [messages, setMessages] = useState(menuMessages['pt'])

  useEffect(() => {
    const localePreference = localStorage.getItem(LANGUAGE_PREFERENCE_KEY)

    if (localePreference) {
      setLocale(localePreference)
      setMessages(menuMessages[localePreference])
    }
  }, [])

  // ** Switches Language
  const switchLanguage = lang => {
    localStorage.setItem(LANGUAGE_PREFERENCE_KEY, lang)

    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='pt'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
