// ** Initial State
const initialState = {
  pending: true,
  success: false,
  allModules: [],
  selectedInfotisModule: null,
  selectedInfotisEnding: null,
  moduleCatalog: []
}

const infotisModules = (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_INFOTIS_MODULE':
      return {
        ...state,
        selectedInfotisModule: null,
        selectedInfotisEnding: null,
        pending: true
      }
    case 'FINISH_LOADING_INFOTIS_MODULE':
      return {
        ...state,
        pending: false
      }
    case 'GET_ALL_MODULES':
      return {
        ...state,
        allModules: action.allModules,
        pending: false
      }
    case 'GET_INFOTIS_MODULE':
      return {
        ...state,
        selectedInfotisModule: action.selectedInfotisModule,
        pending: false
       }
    case 'ADD_INFOTIS_MODULE':
      return {
        ...state
       }
      case 'PUT_INFOTIS_MODULE':
        return {
          ...state,
          infotisModule: action.infotisModule
         }
      case 'DELETE_INFOTIS_MODULE':
      return {
        ...state,
        success: true
       }
      case 'ADD_INFOTIS_ENDING':
      return {
        ...state
       }
      case 'GET_INFOTIS_ENDING':
      return {
        ...state,
        selectedInfotisEnding: action.selectedInfotisEnding,
        pending: false
      }
    case 'ADD_INFOTIS_QUESTION':
      return {
        ...state
      }
    case 'ADD_INFOTIS_QUESTION_DATA':
      return {
        ...state
      }
    case 'BEGIN_LOADING_MODULE_CATALOG':
      return {
        ...state,
        moduleCatalog: [],
        pending: false
      }
    case 'GET_MODULE_CATALOG':
      return {
        ...state,
        moduleCatalog: action.moduleCatalog,
        pending: false
      }
    default:
      return { ...state }
  }
}
export default infotisModules
