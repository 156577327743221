// ** Default weapon data
const defaultWeapon = {
  id: null,
  model: "",
  maker: "",
  caliber: null,
  rechargeTime: null,
  bulletCount : null,
  picture : null,
  isActive: true
}

// ** Initial State
const initialState = {
  pending: false,
  allWeapons: [],
  selectedWeapon: {...defaultWeapon}
}

const weapons = (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_WEAPON':
      return {
        ...state,
        pending: true
      }
    case 'FINISH_LOADING_WEAPON':
      return {
        ...state,
        pending: false
      }
    case 'RESET_SELECTED_WEAPON':
      return {
        ...state,
        selectedWeapon: {...defaultWeapon}
      }
    case 'GET_ALL_WEAPONS':
      return {
        ...state,
        allWeapons: action.allWeapons,
        pending: false
       }
    case 'GET_WEAPON':
      return {
        ...state,
        selectedWeapon: action.selectedWeapon,
        pending: false
       }
    case 'ADD_WEAPON':
      return {
        ...state
       }
    case 'UPLOAD_WEAPON_IMAGE':
      return {
        ...state
       }
    case 'DELETE_WEAPON_IMAGE':
      return {
        ...state
       }
    case 'EDIT_WEAPON':
      return {
        ...state,
        selectedWeapon: action.selectedWeapon
       }
    case 'DELETE_WEAPON':
    return {
      ...state,
      allWeapons: state.allWeapons.filter((entry) => entry.id !== action.deletedId),
      pending: false
     }
    default:
      return { ...state }
  }
}
export default weapons
