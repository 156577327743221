// ** Initial State
const initialState = {
  loading: false
}

const ConfigBanner = (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_COMPANY_BANNER':
      return {
        ...state,
        loading: true
      }
    case 'UPLOAD_COMPANY_BANNER':
      return {
        ...state
      }
    case 'FINISH_COMPANY_BANNER':
        return {
          ...state,
          loading: false
        }
    default:
      return state
  }
}
export default ConfigBanner
