// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    sessionStorage.setItem('userData', JSON.stringify(data))
    sessionStorage.setItem(config.storageTokenKeyName, data.accessToken)
    sessionStorage.setItem(config.storageRefreshTokenKeyName, data.refreshToken)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    sessionStorage.removeItem('userData')
    sessionStorage.removeItem(config.storageTokenKeyName)
    sessionStorage.removeItem(config.storageRefreshTokenKeyName)
  }
}
