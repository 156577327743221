const overviewReportInitialState = {
  loading: false,
  qsEmbedUrl: null,
  lastEmbedUrlReceivedAt: null,
  companyPlayers: null,
  companySessions: null,
  companyScripts: null
}

const overviewReport = (state = overviewReportInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_OVERVIEW_REPORT':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_OVERVIEW_REPORT':
      return {
        ...state,
        loading: false
      }
    case 'GET_OVERVIEW_REPORT':
      return {
        ...state,
        qsEmbedUrl: action.qsEmbedUrl,
        companyPlayers: action.companyPlayers,
        companySessions: action.companySessions,
        companyScripts: action.companyScripts,
        lastEmbedUrlReceivedAt: new Date(),
        loading: false
       }
    default:
      return { ...state }
  }
}
export default overviewReport
