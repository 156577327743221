// ** Initial State
const initialState = {
  tasks: [],
  selectedTask: {},
  params: {
    filter: '',
    q: '',
    sort: '',
    tag: ''
  },
  loading: false,
  qsEmbedUrl: null,
  lastEmbedUrlReceivedAt: null
}

const TodoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TASKS':
      return { ...state, tasks: action.tasks, params: action.params }
    case 'UPDATE_TASKS':
      return { ...state }
    case 'REORDER_TASKS':
      return { ...state, tasks: action.tasks }
    case 'SELECT_TASK':
      return { ...state, selectedTask: action.task }
    case 'BEGIN_LOADING_HOME_REPORT':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_HOME_REPORT':
      return {
        ...state,
        loading: false
      }
    case 'GET_HOME_REPORT':
      return {
        ...state,
        qsEmbedUrl: action.qsEmbedUrl,
        lastEmbedUrlReceivedAt: new Date(),
        loading: false
        }
    default:
      return state
  }
}
export default TodoReducer
