import React, { useContext, useRef, useState } from "react"
// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/actions/auth'
// ** Third party imports
import IdleTimer from "react-idle-timer"
import { Card, CardBody, Button } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'

// ** Component to render changed language text.
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom"
// ** Internal imports
import paths from '@src/router/routes/paths'

// const MySwal = withReactContent(Swal)

const SweetAlertOptions = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const idleTimer = useRef(null)

  const handleTimeoutAlert = () => {
    let timerInterval
    Swal.fire({
      title: intl.formatMessage({ id: "session.timeout" }),
      html: intl.formatMessage({ id: "session.timeout.msg" }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: "Logout" }),
      confirmButtonText: intl.formatMessage({ id: "session.continue" }),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      timer: 60000, //Correct is 60000
      onBeforeOpen() {
        Swal.showLoading()
        timerInterval = setInterval(function () {
          Swal.getContent().querySelector('strong').textContent = Math.ceil(Swal.getTimerLeft() / 1000)
        }, 100)
      },
      onClose() {
        clearInterval(timerInterval)
      }
    }).then(function (result) {
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("Session logged out!")
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: intl.formatMessage({ id: "session.loggedout" }),
          text: intl.formatMessage({ id: "session.loggedout.login" }),
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        dispatch(handleLogout()) //Logout and go to Login page
        history.go(paths.LOGIN) //Force login page to show
      } else if (result.value) {
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // console.log("logged out")
        dispatch(handleLogout()) //Logout and go to Login page
        history.go(paths.LOGIN) //Force login page to show
      }
    })
  }

  const onActive = () => {
    // console.log("active")
    // timer reset automatically.
  }

  const onIdle = () => {
    // console.log("idle")
    const pathname = window.location.pathname //returns the current url minus the domain name
    if (pathname !== "/login") handleTimeoutAlert() //Execute only in other pages
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={1740000} //Correct is 1740000 ms or 29min
      />
    </>
  )
}

export default SweetAlertOptions
