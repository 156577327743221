const defaultPlayer = {
  ref: "",
  email: "",
  playername: "",
  defaultLanguage: "",
  duty: "",
  organization: "",
  phone: "",
  sector: "",
  birthdate: null,
  isTutor: false,
  picture: null,
  apps: []
}

const playerInitialState = {
  loading: false,
  loadingLanguages: false,
  allPlayers: [],
  languagesAvailable: [],
  players: [],
  selectedPlayer: {...defaultPlayer}
}

const players = (state = playerInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_PLAYER':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_PLAYER':
      return {
        ...state,
        loading: false
      }
    case 'BEGIN_LOADING_LANGUAGES':
      return {
        ...state,
        loadingLanguages: true
      }
    case 'FINISH_LOADING_LANGUAGES':
      return {
        ...state,
        loadingLanguages: false
      }
    case 'RESET_SELECTED_PLAYER':
      return {
        ...state,
        selectedPlayer: {...defaultPlayer}
      }
    case 'GET_LANGUAGES':
      return {
        ...state,
        languagesAvailable: action.languagesAvailable,
        loadingLanguages: false
       }
    case 'GET_PLAYERS':
      return {
        ...state,
        allPlayers: action.allPlayers,
        loading: false
       }
    case 'GET_PLAYER':
      return {
        ...state,
        selectedPlayer: action.selectedPlayer,
        loading: false
       }
    case 'ADD_PLAYER':
      return {
        ...state
       }
    case 'UPLOAD_PLAYER_IMAGE':
      return {
        ...state
       }
    case 'DELETE_PLAYER_IMAGE':
      return {
        ...state
       }
    case 'ADD_OR_UPDATE_PLAYERS':
      return {
        ...state,
        loading: false
       }
    case 'EDIT_PLAYER':
      return {
        ...state
       }
    case 'INACTIVATE_PLAYER':
      const allPlayers = [...state.allPlayers]

      const player = allPlayers.find((player) => player.ref === action.playerRef)
      player.apps = player.apps.map((entry) => ({...entry, isActive: false}))

      return {
        ...state,
        allPlayers,
        loading: false
       }
    default:
      return { ...state }
  }
}
export default players
