const defaultUser = {
  id: null,
  name: "",
  email: "",
  role: "ROLE_ADMIN",
  isActive: true
}

const userInitialState = {
  loading: false,
  allUsers: [],
  selectedUser: {...defaultUser}
}

const users = (state = userInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_USER':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_LOADING_USER':
      return {
        ...state,
        loading: false
      }
    case 'RESET_SELECTED_USER':
      return {
        ...state,
        selectedUser: {...defaultUser}
      }
    case 'GET_USERS':
      return {
        ...state,
        allUsers: action.allUsers,
        loading: false
       }
    case 'GET_USER':
      return {
        ...state,
        selectedUser: action.selectedUser,
        loading: false
       }
    case 'INVITE_USER':
      return {
        ...state
       }
    case 'RECOVER_USER':
      return {
        ...state,
        loading: false
       }
    case 'EDIT_USER':
      return {
        ...state
       }
    default:
      return { ...state }
  }
}
export default users
