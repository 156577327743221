// ** Initial State
const defaultInfotisSession = {
  id: null,
  name: "",
  initialDate: null,
  finalDate: null,
  trackId: "",
  scriptMap: [],
  users: []
}

const sessionInitialState = {
  loading: false,
  allData: [],
  qsReportUrl: null,
  selectedInfotisSession: {...defaultInfotisSession}
}

const infotisSessions = (state = sessionInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_INFOTIS_SESSION':
      return {
        ...state,
        loading: true
       }
    case 'FINISH_LOADING_INFOTIS_SESSION':
      return {
        ...state,
        loading: false
       }
    case 'RESET_SELECTED_INFOTIS_SESSION':
      return {
        ...state,
        selectedInfotisSession: {...defaultInfotisSession}
      }
    case 'GET_INFOTIS_SESSIONS':
      return {
        ...state,
        allData: action.allData,
        loading: false
       }
    case 'GET_INFOTIS_SESSION':
      return {
        ...state,
        selectedInfotisSession: action.selectedInfotisSession,
        loading: false
       }
    case 'ADD_INFOTIS_SESSION':
      return {
        ...state
       }
    case 'EDIT_INFOTIS_SESSION':
      return {
        ...state,
        selectedInfotisSession: action.selectedInfotisSession
       }
    case 'DELETE_INFOTIS_SESSION':
      return {
        ...state,
        allData: state.allData.filter((entry) => entry.id !== action.deletedId),
        loading: false
       }
    case 'BEGIN_LOAD_QS_BIOTELEMETRY_REPORT':
      return {
        ...state,
        loading: true
      }
    case 'GET_QS_BIOTELEMETRY_REPORT':
        //console.log(action)
        return {
          ...state,
          qsReportUrl: action.qsReportUrl,
          loading: false
         }
    default:
      return { ...state }
  }
}
export default infotisSessions
