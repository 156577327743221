// ** Initial State
const appInitialState = {
  loading: false,
  allData: []
}

const apps = (state = appInitialState, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING_APP':
      return {
        ...state,
        loading: true
       }
    case 'FINISH_LOADING_APP':
      return {
        ...state,
        loading: false
       }
    case 'GET_ALL_APPS':
      return {
        ...state,
        allData: action.allData,
        loading: false
       }
    case 'PUBLISH_APP':
      return {
        ...state,
        loading: false
       }
    default:
      return { ...state }
  }
}
export default apps
